<!--
 * @Description: 车行道停车运营管理-路段管理-路段泊位-泊位管理 onroadRoadParkSpaceNext
 * @Author: zhoucheng
 * @Github: https://github.com/zhoucheng-tt
 * @Date: 2021-04-20 11:25:48
 * @LastEditors: zhoucheng
-->
<template>
  <div class='dt-onroadParkRoad'>
    <el-row class="dt-back">
      <i class="el-icon-back"
         @click="handleBack"></i>
      <span>路段区域信息管理</span>
    </el-row>
    <el-row class="tableRow">
      <el-row class="tableTitle">
        <el-col :span="12">泊位管理</el-col>
        <el-col :span="12"
                class="dt-button-box">
          <el-button type="primary"
                     @click="insertUser">新 增</el-button>
          <el-button type="success"
                     style="margin-left:17px;"
                     @click="importDialog = true">导 入</el-button>
          <el-button type="danger"
                     style="margin-left:17px;"
                     @click="handleDelList">批量删除</el-button>
        </el-col>
      </el-row>
      <el-row class="tableContent">
        <dt-table :tableColumnList="tableColumnList"
                  :data="tableList"
                  :map="listMap"
                  :tableConfig='tableConfig'
                  :paginationConfig='paginationConfig'
                  @getList="getList"
                  :tableFun="tableFun">
          <template slot="isFirst">
            <el-table-column type="selection"></el-table-column>
          </template>
          <template slot="operating">
            <el-table-column label="操作">
              <template slot-scope="scope">
                <el-col :span="8">
                  <el-button @click="handleOrderUpdate(scope.$index, scope.row)"
                             type="text"
                             size="medium">编辑</el-button>
                </el-col>
                <el-col :span="8">
                  <el-button type="text"
                             size="medium"
                             @click="handleOrderDelete(scope.$index, scope.row)">删除</el-button>
                </el-col>
              </template>
            </el-table-column>
          </template>
        </dt-table>
      </el-row>
    </el-row>
    <!-- 新增、修改 -->
    <el-dialog :title="dialogTitle"
               append-to-body
               @close="popClose"
               :visible.sync="insterDialog">
      <el-form :model="instreForm"
               :label-position="labelPosition"
               :rules="rules"
               ref="instreForm"
               size="small"
               label-width="110px">
        <el-row justify="space-around">
          <el-col :span="12">
            <el-form-item label="车位编号"
                          prop="parkSpaceId">
              <el-input v-model="instreForm.parkSpaceId"
                        style="width:63%"
                        class="width input"
                        placeholder="请输入"></el-input>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row justify="space-around">
          <el-col :span="12">
            <el-form-item label="检测器类型"
                          prop="parkSpaceDetecterTypeCode">
              <el-select class="width"
                         style="width:63%"
                         v-model="instreForm.parkSpaceDetecterTypeCode"
                         placeholder="请选择">
                <el-option v-for="(item, index) in facilityType"
                           :key="index"
                           :label="item.name"
                           :value="item.code"></el-option>
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="检测器编号"
                          prop="parkSpaceDetecterId">
              <el-input v-model="instreForm.parkSpaceDetecterId"
                        style="width:63%"
                        class="width input"
                        placeholder="请输入"></el-input>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row justify="space-around">
          <el-col :span="12">
            <el-form-item label="地锁编号"
                          prop="groundLock">
              <el-input v-model="instreForm.groundLock"
                        style="width:63%"
                        class="width input"
                        placeholder="请输入"></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="车位朝向"
                          prop="angle">
              <el-input v-model="instreForm.angle"
                        style="width:63%"
                        class="width input"
                        placeholder="请输入"></el-input>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row justify="space-around">
          <el-col :span="12">
            <el-form-item label="经度"
                          prop="xAxis">
              <el-input v-model="instreForm.xAxis"
                        style="width:63%"
                        class="width input"
                        placeholder="请输入"></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="纬度"
                          prop="yAxis">
              <el-input v-model="instreForm.yAxis"
                        style="width:63%"
                        class="width input"
                        placeholder="请输入"></el-input>
            </el-form-item>
          </el-col>
        </el-row>
        <el-form-item>
          <el-row class="dt-button-box">
            <el-button type="success"
                       size="medium"
                       @click="handleSure('instreForm')">确 定</el-button>
          </el-row>
        </el-form-item>
      </el-form>
    </el-dialog>
    <!-- 导入 -->
    <el-dialog title="导入数据"
               :visible.sync="importDialog"
               :close-on-click-modal="false"
               append-to-body>
      <el-upload ref="upload"
                 :auto-upload="false"
                 :file-list="fileList"
                 :http-request="myUpload"
                 :limit="1"
                 :on-change="addFile"
                 :on-exceed="handleExceed"
                 :show-file-list="true"
                 accept=".xls, .xlsx"
                 action=""
                 style="text-align: center;">
        <el-row style="display:flex">
          <el-button size="small"
                     style="margin-left: 15px"
                     type="primary"
                     @click="queryTemplateDl">
            模板下载
          </el-button>
          <el-button size="small"
                     type="primary">
            选择文件
          </el-button>
        </el-row>
        <div slot="tip"
             class="el-upload__tip"
             style="font-size:10px;color:#ff0000;margin-top:30px;">
          请先下载模板！
        </div>
      </el-upload>
      <el-row type="flex"
              justify="end">
        <el-col :span="12"
                class="dt-button-box">
          <el-button type="primary"
                     class="popBtn"
                     @click="confimImportBatch">导 入</el-button>
        </el-col>
      </el-row>
    </el-dialog>
  </div>
</template>

<script>
//这里可以导入其他文件（比如：组件，工具js，第三方插件js，json文件，图片文件等等）
//例如：import 《组件名称》 from '《组件路径》';
import dtTable from '@/components/table.vue'

export default {
  //import引入的组件需要注入到对象中才能使用
  components: { dtTable },
  data () {
    //这里存放数据
    return {
      tableFun: { 'selection-change': this.handleChange, },
      tableConfig: { border: true },
      paginationConfig: { layout: 'prev,sizes,pager,next,jumper', total: 0 },
      listMap: { pageNum: 1, pageSize: 15, },
      tableList: { list: [] },
      pageNum: 1, // 分页
      pageSize: 15,
      searchForm: {
        parkLayerId: '',// 平层id
        parkId: '',// 路段id
      },
      tableColumnList: [
        {
          prop: 'parkSpaceId',
          label: '泊位号',
        },
        {
          prop: 'parkSpaceDetecterTypeName',
          label: '检测器类型',
        },
        {
          prop: 'parkSpaceDetecterId',
          label: '检测器编号',
        },
      ],
      MultiSelect: [], // 多选列表
      labelPosition: 'left', //对齐方向
      dialogVisible: false,
      instreForm: {},// 新增表
      insterDialog: false,// 新增修改弹窗开关
      dialogTitle: '',//弹出框标题
      //设备类型
      facilityType: [
        { name: '地磁传感器', code: 1 },
        { name: '视频车位检测器', code: 2 }
      ],
      rules: {
        parkSpaceId: [{ required: true, message: '请输入车位编号', trigger: 'blur' }],
        xAxis: [{ required: true, message: '请输入车位x坐标', trigger: 'blur' }],
        yAxis: [{ required: true, message: '请输入车位y坐标', trigger: 'blur' }],
      },
      importDialog: false, // 导入弹窗
      fileList: [],
    };
  },
  //监听属性 类似于data概念
  computed: {

  },
  //监控data中的数据变化
  watch: {},
  //生命周期 - 创建完成（可以访问当前this实例）
  created () {
  },
  //生命周期 - 挂载完成（可以访问DOM元素）
  mounted () {
    this.searchForm.parkLayerId = this.$route.query.parkLayerId
    this.searchForm.parkId = this.$route.query.parkId
    this.queryTableList()
  },
  beforeCreate () { }, //生命周期 - 创建之前
  beforeMount () { }, //生命周期 - 挂载之前
  beforeUpdate () { }, //生命周期 - 更新之前
  updated () { }, //生命周期 - 更新之后
  beforeDestroy () { }, //生命周期 - 销毁之前
  destroyed () { }, //生命周期 - 销毁完成
  activated () { }, //如果页面有keep-alive缓存功能，这个函数会触发
  //方法集合
  methods: {
    // 导入
    queryTemplateDl () {
      let arr = new Array()
      arr[0] = "parkId"
      arr[1] = "parkLayerId"
      this.$axios.get(this.$downloadBaseUrl + "ParkSpaceController/downloadTemplate?param=" + encodeURI(JSON.stringify(arr)), {
        responseType: "blob",
        headers: {
          'userToken': localStorage.getItem("userToken")
        },
      }).then(res => {
        let blob = new Blob([res.data], {
          type: "application/vnd.ms-excel",
        }); // 2.获取请求返回的response对象中的blob 设置文件类型，这里以excel为例
        let url = window.URL.createObjectURL(blob); // 3.创建一个临时的url指向blob对象
        // 4.创建url之后可以模拟对此文件对象的一系列操作，例如：预览、下载
        let a = document.createElement("a");
        a.href = url;
        a.download = "车位模板.xlsx";
        a.click();
        // 5.释放这个临时的对象url
        window.URL.revokeObjectURL(url);
      })
    },
    // 上传文件
    addFile (file) {
      if (!(file.name.endsWith("xls") || file.name.endsWith("xlsx"))) {
        this.fileList = [];
        this.$message.warning(`文件格式有误,请选择正确的Excel文件`);
      }
    },
    // 限制文件
    handleExceed () {
      this.$message.warning(`对不起,一次仅限上传一个文件！`);
    },
    // 上传文件  
    myUpload (content) {
      let _self = this;
      var FileController = "";
      FileController = this.$downloadBaseUrl + "ParkSpaceController/upload" + "?parkId=" + this.$route.query.parkId + "&parkLayerId=" + this.$route.query.parkLayerId;
      var form = new FormData();
      form.append("file", content.file);
      var xhr = new XMLHttpRequest();
      xhr.onreadystatechange = onloadFun;
      xhr.open("POST", FileController, true);
      xhr.send(form);
      function onloadFun () {
        if (xhr.readyState == 4 && xhr.status == 200) {
          var resText = JSON.parse(xhr.responseText);
          if (resText.resultCode === "2000") {
            _self.fileList = [];
            _self.$message({ message: "导入成功", type: "success" });
            _self.importDialog = false;
            _self.queryTableList();
          } else {
            _self.$message.error({ message: "对不起！文件上传失败", type: "error" });
          }
        }
      }
    },
    // 确认导入按钮
    confimImportBatch () {
      this.$refs.upload.submit();
      this.importDialog = false;
    },
    // 获取车位信息
    queryTableList () {
      this.searchForm['pageNum'] = this.pageNum;
      this.searchForm['pageSize'] = this.pageSize;
      this.$yardManagementApi.ParkSpaceController(this.searchForm).then(response => {
        this.tableList.list = response.resultEntity.list
        this.paginationConfig.total = response.resultEntity.total
      })
    },
    //分页器
    getList (change) {
      this.pageSize = change.pageSize
      this.pageNum = change.pageNum
      this.queryTableList()
    },
    // 新增
    insertUser () {
      this.dialogTitle = '新增'
      this.insterDialog = true
    },
    //多选
    handleChange (val) {
      this.MultiSelect = []
      for (let index in val) {
        let info = { parkId: this.searchForm.parkId, parkSpaceId: val[index].parkSpaceId }
        this.MultiSelect.push(info)
      }
    },
    // 批量删除按钮
    handleDelList () {
      if (this.MultiSelect.length > 0) {
        this.delete()
      } else {
        this.$message({ showClose: true, message: '请选择要删除的数据' });
      }
    },
    // 删除操作
    delete () {
      this.$confirm('确认删除?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning',
      }).then(() => {
        this.$yardManagementApi.delParkSpace(this.MultiSelect).then(() => {
          this.$message({ showClose: true, message: '删除成功', type: 'success' });
          this.queryTableList()
        })
      })
    },
    //删除
    handleOrderDelete (index, row) {
      this.MultiSelect = []
      this.MultiSelect.push({ parkId: this.searchForm.parkId, parkSpaceId: row.parkSpaceId })
      this.delete()
    },
    //修改
    handleOrderUpdate (index, row) {
      this.instreForm = this.$deepClone(row)
      this.dialogTitle = '修改'
      this.insterDialog = true
    },
    // 确定
    handleSure (formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          if (this.dialogTitle === '新增') {
            this.$yardManagementApi.insertParkSpace({ ...this.searchForm, ...this.instreForm }).then(() => {
              this.$message({ showClose: true, message: '新增成功', type: 'success' })
              this.queryTableList()
              this.insterDialog = false
            })
          } else if (this.dialogTitle === '修改') {
            this.$yardManagementApi.updateParkSpace({ ...this.searchForm, ...this.instreForm }).then(() => {
              this.$message({ showClose: true, message: '修改成功', type: 'success' });
              this.queryTableList()
              this.insterDialog = false
            })
          }
        } else {
          return false;
        }
      });
    },
    popClose () {
      this.instreForm = {}
      this.$refs.instreForm.resetFields();
    },
    // 返回箭头
    handleBack () {
      window.history.back(-1);
    },
  },
}
</script>
<style lang='scss' scoped>
$topSelectHeight: 130px;
.dt-onroadParkRoad {
  height: 100%;
  .dt-back {
    color: #bae8ff;
    font-size: 16px;
    line-height: 22px;
    font-weight: 500;
  }
  .topSelectTwoLine {
    width: 100%;
    height: $topSelectHeight;
    @include background('blockBg');
    padding-top: 24.3px;
    padding-left: 21px;
    .topButton {
      display: flex;
      padding-left: 91px;
    }
  }
  .tableRow {
    width: 100%;
    @include background('blockBg');
    margin-top: 16px;
    padding-left: 20.2px;
    padding-right: 20.2px;
    padding-top: 5px;
    .tableTitle {
      height: 32px;
      line-height: 32px;
      margin-top: 15px;
    }
    .tableContent {
      margin-top: 10px;
    }
  }
}
.dt-button-box {
  display: flex;
  justify-content: flex-end;
}
</style>
